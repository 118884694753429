import { useCallback, useEffect, useState } from "react";
import publicRequests from "@/modules/public/requests";

export type TranslateFunc = (text: string, vars?: { [label: string]: string | number }) => string;

const useTranslate = (locale: string, initDictionary?: { [key: string]: string }, target?: "seller") => {
  const [dictionaries, setDictionaries] = useState({ [locale]: initDictionary });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading && !dictionaries[locale]) {
      setLoading(true);

      publicRequests
        .getDictionary(locale, target)
        .then((data) => {
          setDictionaries({ ...dictionaries, [locale]: data });
          setLoading(false);
        })
        .catch((error) => {
          setDictionaries({ ...dictionaries, [locale]: {} });
          console.log("Error: publicRequests.getDictionary()", error);
          setLoading(false);
        });
    }
  }, [dictionaries, loading, locale, target]);

  const translate: TranslateFunc = useCallback(
    (text, vars) => {
      const dict = dictionaries[locale];

      const textWithoutContext = text.replaceAll(/-\([^\)]+\)-/gi, '');
      
      let trans =
        dict && (dict[text] || dict[textWithoutContext])
          ? dict[text] || dict[textWithoutContext]
          : textWithoutContext || text;

      if (vars) {
        Object.keys(vars).forEach((key) => {
          const val = vars[key];
          if (typeof val !== undefined) {
            trans = trans.replaceAll(`{${key}}`, `${val}`);
          }
        });
      }

      return trans;
    },
    [dictionaries, locale]
  );

  return { translate, loading: loading || !dictionaries[locale] };
};

export default useTranslate;
